var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-iterator',{attrs:{"items":_vm.items,"options":_vm.options,"footer-props":{
        itemsPerPageOptions: [
            5,
            10,
            15,
            50
        ]
    },"loading":_vm.loading,"item-key":"id","server-items-length":_vm.totalItems},on:{"update:options":[function($event){_vm.options=$event},function($event){return _vm.$emit('update:options', $event)}]},scopedSlots:_vm._u([{key:"default",fn:function({ items }){return [_c('List',{attrs:{"items":items,"dense":_vm.dense,"all-read":_vm.allRead,"checkable":_vm.checkable}})]}},{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.__('Nenhuma notificação encontrada', 'notifications'))+" ")])],1)],1)]},proxy:true},{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-tbody"}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }